<template>
  <div class="pay">
    <!--定义Vue组件的模板内容-->
    <div class="pay-2">
      <div class="imgs">
        <div class="reload">
          <i
            v-show="!result && !reLoad"
            @click="refresh()"
            class="el-icon-refresh"
          ></i>
          <!-- <i class="el-icon-refresh"></i> -->
          <i v-show="reLoad" class="el-icon-loading"></i>
        </div>
        <div v-show="!result" class="bgImg" @click="refresh()"></div> 
        <div class="demo-image__preview">
          <el-image 
            style="width: 240px; height: 240px"
            :src="imageSrc" >
            <div slot="error" class="image-slot" style="font-size: 16px;text-align: center;" v-if="statusImg === 'error'">
              <div>{{ $t('二维码加载失败') }}</div>
              <i class="iconfont icon-tupianjiazaishibai" style="font-size: 140px;"></i>
            </div>
            <div slot="error" class="image-slot" style="font-size: 16px;text-align: center;" v-else>
              <i class="el-icon-loading" style="font-size: 170px;"></i>
            </div>
          </el-image>
        </div>
      </div>
    </div>
    <span>{{ $t("使用微信支付")}} <i class="iconfont icon-payment"></i></span>
  </div>
</template>

<script>
  import {payResult, submitOrder} from "../../config/api";
  // 定义Vue组件的业务逻辑
  export default {
    // 为组件命名
    name: "RechargePay",
    // 加载子目录组件👇
    // components: {
    // } ,
    // 如果作为子组件向父组件传递
    props: {
      // xxx: { // xxx 引导子组件向父组件👆传值的要素,父组件里要有定义
      //   type: Object,
      //   required: true
      // }
    },
    // 私有数据
    data() {
      return {
        base64Data: "",
        no: "", // 订单号
        result: true, // 支付状态 true：已支付, false：未支付，当 为false 时弹出 刷新二维码
        reLoad: false, // 刷新，false 不显示刷新 ，true 为显示刷新
        timerInterval: null, // 循环定时器
        reloadTimer: null, // 二维码刷新按钮倒计时
        statusImg:"",// 二维码获取状态
      };
    },
    // 创建生命周期函数,立即获取所有元素
    // 当页面刚加载的时候会执行的钩子函数
    created() {
      if (!this.$route.query.buyNum) return this.$router.go(-1);
    },
    mounted() {
      this.submit();
      this.reloadImg(); // 刷新重新加载请求
      console.log(this.base64Data)
    },
    // 处理函数
    methods: {
      submit() {
        let params = {
          quantity: this.$route.query.buyNum
        };
        submitOrder(params)
          .then(res => {
            this.base64Data = res.data.qrcode;
            this.no = res.data.no;
            this.statusImg = res.data.status;
            // 初始化按钮显示信息
            this.reLoad = false;
            this.result = true;
          })
          .catch(error => {
          });
      },
      paying() {
        let params = {
          no: this.no
        };
        payResult(params)
          .then(res => {
            if (res.data.result === 1) {
              clearInterval(this.timerInterval)
              this.$router.push("/paySuccess")
              return
            }
            if (res.data.result === 0) {
            }
            if (res.data.result === -1) {
              clearInterval(this.timerInterval)
              this.$router.push("/payError")
              return
            }
          })
          .catch(() => {
          });
      },
      refresh() {
        this.reLoad = true;
        this.submit();
        this.reloadImg();
      },
      reloadImg() { // 重新请求新二维码
        let reloadTime = 299
        this.reloadTimer = setInterval(() => {
          if (reloadTime > 0) {
            reloadTime -= 1;
          } else {
            this.result = false
            clearInterval(this.reloadTimer);
            clearInterval(this.timerInterval);
          }
        }, 1000);
      },
      rePay() { // 每个两秒请求下接口
        this.timerInterval = setInterval(() => {
          this.paying();
        }, 2000);
      }
    },

    // 将【全局】数据,映射为当前组件的计算属性
    computed: {
      imageSrc() {
        return `data:image/png;base64,${this.base64Data}`;
      }
    },
    watch: {
      no(newVal) {
        if (newVal) this.rePay()
      },
      base64Data(newVal) {
        console.log(newVal)
      }
    },
    // ...其他业务逻辑
    beforeDestroy() {
      // 在页面销毁前停止循环
      clearInterval(this.reloadTimer);
      clearInterval(this.timerInterval);
    },
  };
  </script>

<style lang='less' scoped>
  .pay {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .pay-2 {
      .imgs {
        position: relative;

        .reload, .bgImg {
          position: absolute;
          width: 200px;
          height: 200px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 50px;
          z-index: 1;

          i {
            color: #000;
            background: #fff;
            border-radius: 50%;
            z-index: 10;
          }
        }

        .bgImg {
          background-color: #fff;
          opacity: .4;
        }

        .bgImg:hover {
          cursor: pointer;
        }

        .converted {
          width: 240px;
          height: 240px;
        }
      }
    }

    .iconfont {
      color: #0fa47f;
    }
    .icon-tupianjiazaishibai,
    .el-icon-loading {
      color: #d3d3d3;
    }
  }
</style>
